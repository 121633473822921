import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Info, DollarSign, Lock, Coins } from 'lucide-react';
import { useCynths } from '../../context/useCynths';
import { useWalletClient } from 'wagmi';
import { readContract } from '@wagmi/core';
import { ethers } from 'ethers';
import erc20abi from '../../abi/ERC20_ABI.json';
import { erc20Address } from '../../.config.js';

const getTokenIcon = (ticker) => {
  try {
    return require(`../../assets/unisynths/${ticker}.png`);
  } catch (err) {
    return null;
  }
};

const MintBurn = () => {
  const [selectedTokenType, setSelectedTokenType] = useState('Specials');
  const [selectedToken, setSelectedToken] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [selectedAction, setSelectedAction] = useState('mint');
  const { data: walletClient } = useWalletClient();
  const [globalStats, setGlobalStats] = useState({
    totalSupplyValue: '0',
    collateralization: 'N/A',
    totalTokens: '0',
  });
  const [tokensByCategory, setTokensByCategory] = useState({});
  const [btcWeight, setBtcWeight] = useState('0%');
  const [goldWeight, setGoldWeight] = useState('0%');
  const [sdrBasket, setSdrBasket] = useState(null);
  const [marketCap, setMarketCap] = useState(null);
  const [currentFee, setCurrentFee] = useState(0);
  const [cranniBalance, setCranniBalance] = useState(0);
  const [userTokenBalance, setUserTokenBalance] = useState(0);
  const [userUSDTBalance, setUserUSDTBalance] = useState(0);

  const tokenTypes = ['Specials', 'Commodities', 'Equities', 'Forex', 'Crypto'];

  const {
    mint,
    burn,
    getTotalMinted,
    getTotalSupply,
    getTokenList,
    getTokenPrice,
    getBoldPrice,
    getSdrPrice,
    getMuskPrice,
    getPrivPrice,
    getBtcWeight,
    getGoldWeight,
    getSdrBasket,
    formatCurrency,
    getCurrentFee,
    getCranniBalance
  } = useCynths();

  const getPriceForToken = async (ticker) => {
    switch (ticker) {
      case 'ciBOLD':
        return await getBoldPrice();
      case 'ciMUSK':
        return await getMuskPrice();
      case 'ciPRIV':
        return await getPrivPrice();
      case 'ciSDR':
        return await getSdrPrice();
      default:
        return await getTokenPrice(ticker);
    }
  };

  const enrichToken = async (token) => {
    const price = await getPriceForToken(token.ticker);
    return { ...token, price };
  };

  const groupTokensByCategory = (tokens) => {
    return tokens.reduce((acc, token) => {
      const category = token.category || 'Others';
      if (!acc[category]) acc[category] = [];
      acc[category].push(token);
      return acc;
    }, {});
  };

  const fetchCranniBalance = async () => {
    if (walletClient?.account?.address) {
      const balance = await getCranniBalance(walletClient.account.address);
      setCranniBalance(Number(balance));
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        console.log("FETCHING CONTRACT DATA..");

        const minted = await getTotalMinted();
        const supply = await getTotalSupply();
        const tokenListFromSC = await getTokenList();

        const enrichedTokens = await Promise.all(
          tokenListFromSC.map(async (token) => await enrichToken(token))
        );

        const grouped = groupTokensByCategory(enrichedTokens);
        setTokensByCategory(grouped);

        const collateralization =
          parseFloat(minted) > 0
            ? ((parseFloat(supply) / parseFloat(minted)) * 100).toFixed(2) + '%'
            : 'N/A';

        setGlobalStats({
          totalSupplyValue: supply,
          totalTokens: enrichedTokens.length,
          collateralization: collateralization,
        });

        const btc = await getBtcWeight();
        const gold = await getGoldWeight();
        setBtcWeight(btc);
        setGoldWeight(gold);

        const basket = await getSdrBasket();
        setSdrBasket(basket);

        const fee = await getCurrentFee();
        setCurrentFee(Number(fee));
        await fetchCranniBalance();

      } catch (err) {
        console.error('Error fetching contract data:', err);
      }
    }

    fetchData();
    const interval = setInterval(fetchData, 30000);
    return () => clearInterval(interval);
  }, [selectedToken, walletClient]);

  useEffect(() => {
    async function fetchMarketCap(token) {
      try {
        const totalSupplyBN = await readContract({
          address: token.tokenAddress,
          abi: erc20abi.abi,
          functionName: 'totalSupply',
        });

        const totalSupply = parseFloat(ethers.utils.formatUnits(totalSupplyBN, 18));
        const price = parseFloat(token.price);
        const cap = totalSupply * price;
        setMarketCap(cap);
      } catch (error) {
        console.error("Error fetching market cap:", error);
        setMarketCap(null);
      }
    }
    if (selectedToken) {
      fetchMarketCap(selectedToken);
    } else {
      setMarketCap(null);
    }
  }, [selectedToken]);

  useEffect(() => {
    const fetchBalances = async () => {
      if (walletClient?.account?.address && selectedToken) {
        try {

          const tokenBalance = await readContract({
            address: selectedToken.tokenAddress,
            abi: erc20abi.abi,
            functionName: 'balanceOf',
            args: [walletClient.account.address]
          });
          setUserTokenBalance(ethers.utils.formatUnits(tokenBalance, 18));


          const usdtAddress = erc20Address;
          const usdtBalance = await readContract({
            address: usdtAddress,
            abi: erc20abi.abi,
            functionName: 'balanceOf',
            args: [walletClient.account.address]
          });
          setUserUSDTBalance(ethers.utils.formatUnits(usdtBalance, 6));
        } catch (error) {
          console.error('Error fetching balances:', error);
        }
      }
    };

    fetchBalances();
  }, [walletClient, selectedToken, inputValue]);

  const handleMax = () => {
    const balance = selectedAction === 'mint'
      ? userUSDTBalance
      : userTokenBalance;
    setInputValue(balance);
  };

  const handleHalf = () => {
    const balance = selectedAction === 'mint'
      ? userUSDTBalance
      : userTokenBalance;
    setInputValue((Number(balance) / 2).toString());
  };

  const handleTokenTypeChange = (type) => {
    setSelectedTokenType(type);
    setSelectedToken(null);
    setInputValue('');
  };

  const handleTokenSelection = (token) => {
    setSelectedToken(token);
    setInputValue('');
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleActionSelection = (action) => {
    setSelectedAction(action);
    setInputValue('');
  };

  const getPreviewText = () => {
    if (!inputValue || !selectedToken) return '';

    const inputAmount = parseFloat(inputValue);
    if (isNaN(inputAmount)) return '';

    if (selectedAction === 'mint') {

      const cranniBalanceNum = Number(cranniBalance);
      const hasCranniDiscount = cranniBalanceNum >= 888 * 10 ** 8;
      const effectiveAmount = hasCranniDiscount ? inputAmount : inputAmount * 0.99;
      const tokens = (effectiveAmount / Number(selectedToken.price)).toFixed(4);

      return `You will receive ${tokens} ${selectedToken.ticker} ${hasCranniDiscount ? '(0% fee - CRANNI Holder)' : '(1% mint fee applied)'
        }`;
    } else {

      const feePercentage = Number(currentFee) / 10;
      const usdtAmount = inputAmount * Number(selectedToken.price);
      const finalAmount = usdtAmount * (1 - (Number(currentFee) / 1000));

      return `You will receive ${finalAmount.toFixed(2)} USDT (${feePercentage}% burn fee)`;
    }
  };

  const addToWallet = async () => {
    if (!selectedToken) return;
    try {
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: selectedToken.tokenAddress,
            symbol: selectedToken.ticker,
            decimals: 18,
            image: getTokenIcon(selectedToken.ticker) || 'https://via.placeholder.com/32'
          }
        }
      });
      Swal.fire("Success", `${selectedToken.ticker} added to Wallet!`, "success");
    } catch (error) {
      console.error('Failed to add token to Wallet:', error);
      Swal.fire("Error", "Failed to add token to Wallet.", "error");
    }
  };

  const executeTransaction = async () => {
    if (!selectedToken || !inputValue) return;
    const actionText = selectedAction === 'mint' ? 'mint' : 'burn';
    Swal.fire({
      title: `Confirm ${actionText.charAt(0).toUpperCase() + actionText.slice(1)}`,
      text: `Are you sure you want to ${actionText} tokens for ${selectedToken.ticker}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, confirm!',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (selectedAction === 'mint') {
            await mint(selectedToken.ticker, inputValue);
          } else {
            await burn(selectedToken.ticker, inputValue, walletClient);
          }
          Swal.fire("Success", `${actionText.charAt(0).toUpperCase() + actionText.slice(1)} successful!`, "success");
        } catch (error) {
          Swal.fire("Error", error.message || "Transaction failed", "error");
        }
      }
    });
  };

  const displayedTokens = tokensByCategory[selectedTokenType] || [];

  return (
    <div className="min-h-screen md:p-6 space-y-6">
      <div className="mb-8">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400 mb-2 mt-5">
            Cynths
          </h1>
          <p className="text-purple-300/60 mb-6">
            Create and manage synthetic tokens that track real-world assets. Our platform enables you to mint and burn tokens with full collateralization and real-time price feeds.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 bg-gradient-to-b from-[#010101] to-[#00102a] backdrop-blur-sm border border-purple-500/30 rounded-xl p-6">
          <div className="rounded-lg bg-gradient-to-br from-purple-900/50 to-blue-900/50 border border-purple-500/20 p-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-400 text-sm">Total Value Locked</p>
                <p className="text-2xl font-bold text-white">
                  {formatCurrency(globalStats.totalSupplyValue, "USD")}
                </p>
              </div>
              <DollarSign className="h-8 w-8 text-purple-400" />
            </div>
          </div>

          <div className="rounded-lg bg-gradient-to-br from-purple-900/50 to-blue-900/50 border border-purple-500/20 p-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-400 text-sm">Collateralization Ratio</p>
                <p className="text-2xl font-bold text-white">{globalStats.collateralization}</p>
              </div>
              <Lock className="h-8 w-8 text-purple-400" />
            </div>
          </div>

          <div className="rounded-lg bg-gradient-to-br from-purple-900/50 to-blue-900/50 border border-purple-500/20 p-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-400 text-sm">Total Tokens</p>
                <p className="text-2xl font-bold text-white">{globalStats.totalTokens}</p>
              </div>
              <Coins className="h-8 w-8 text-purple-400" />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-6">
        <div className="space-y-6">
          <div className="rounded-lg bg-gradient-to-br from-gray-900/50 to-gray-800/50 border border-gray-700">
            <div className="rounded-xl p-6 border-b border-gray-700 bg-gradient-to-b from-[#010101] to-[#00102a]">
              <h2 className="text-lg font-semibold text-white">Select Token Type</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                {tokenTypes.map((type) => (
                  <button
                    key={type}
                    className={`py-2 px-3 rounded-lg transition-all duration-300 text-sm font-medium ${selectedTokenType === type
                        ? 'bg-gradient-to-r from-purple-500 to-blue-500 text-white'
                        : 'bg-gray-800 hover:bg-purple-500/20 text-gray-300'
                      }`}
                    onClick={() => handleTokenTypeChange(type)}
                  >
                    {type}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="rounded-lg bg-gradient-to-br from-gray-900/50 to-gray-800/50 border border-gray-700">
            <div className="rounded-xl p-6 border-b border-gray-700 bg-gradient-to-b from-[#010101] to-[#00102a]">
              <h2 className="text-lg font-semibold text-white">Available Tokens</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-2 gap-4">
                {displayedTokens.map((token) => (
                  <div
                    key={token.ticker}
                    onClick={() => handleTokenSelection(token)}
                    className={`p-4 rounded-lg cursor-pointer transition-all duration-300 ${selectedToken?.ticker === token.ticker
                        ? 'bg-gradient-to-br from-purple-500/20 to-blue-500/20 border border-purple-500/50'
                        : 'bg-gray-800/50 border border-gray-700 hover:border-purple-500/30'
                      }`}
                  >
                    <div className="flex flex-col items-center">
                      {getTokenIcon(token.ticker) ? (
                        <img
                          src={getTokenIcon(token.ticker)}
                          alt={token.ticker}
                          className="w-25 h-25 rounded-full"
                        />
                      ) : (
                        <div className="w-14 h-14 mt-3 mb-3 flex items-center justify-center rounded-full bg-gradient-to-r from-purple-600 to-blue-500">
                          <span className="text-white font-bold">
                            {token.ticker.slice(2, 4)}
                          </span>
                        </div>
                      )}
                      <span className="text-sm font-medium text-white">{token.ticker}</span>
                      <span className="text-xs text-gray-400 mt-1">${token.price}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {selectedToken ? (
          <div className="col-span-2 space-y-6">
            <div className="rounded-lg bg-gradient-to-br from-gray-900/50 to-gray-800/50 border border-gray-700 md:mt-0 mt-8">
              <div className="rounded-xl p-6 border-b border-gray-700 bg-gradient-to-b from-[#010101] to-[#00102a]">
                <div className="flex items-center justify-between">
                  <h2 className="text-lg font-semibold text-white">{selectedToken.ticker} Details</h2>
                  <button
                    onClick={addToWallet}
                    className="px-4 py-2 bg-gradient-to-r from-orange-500 to-yellow-500 hover:from-orange-600 hover:to-yellow-600 rounded-lg text-white text-sm font-medium transition-all duration-300"
                  >
                    Add to Wallet
                  </button>
                </div>
              </div>
              <div className="p-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                  <div className="p-4 bg-gray-800/50 rounded-lg">
                    <p className="text-gray-400 text-sm">Price</p>
                    <p className="text-xl font-bold text-white">${selectedToken.price}</p>
                  </div>
                  <div className="p-4 bg-gray-800/50 rounded-lg">
                    <p className="text-gray-400 text-sm">Market Cap</p>
                    <p className="text-xl font-bold text-white">
                      {marketCap !== null
                        ? formatCurrency(marketCap, "USD")
                        : "N/A"}
                    </p>
                  </div>
                </div>

                <div className="space-y-6">
                  <div>
                    <h3 className="text-lg font-medium text-white mb-2">About {selectedToken.ticker}</h3>
                    <p className="text-gray-300">{selectedToken.description}</p>
                  </div>

                  {selectedToken.ticker === 'ciBOLD' && (
                    <div className="mt-4">
                      <h4 className="text-lg font-medium text-white mb-1">Current Allocation Percentages</h4>
                      <p>
                        <span className="text-orange-500 font-bold">BTC: {btcWeight}</span> |{' '}
                        <span className="text-yellow-500 font-bold">Gold: {goldWeight}</span>
                      </p>
                    </div>
                  )}
                  {selectedToken.ticker === 'ciSDR' && sdrBasket && (
                    <div className="mt-4">
                      <h4 className="text-lg font-medium text-white mb-1">Current Fiat Allocations</h4>
                      <p>
                        <span className="font-bold">USD: {sdrBasket.USD}</span> |{' '}
                        <span className="font-bold">EUR: {sdrBasket.EUR}</span> |{' '}
                        <span className="font-bold">CNY: {sdrBasket.CNY}</span> |{' '}
                        <span className="font-bold">GBP: {sdrBasket.GBP}</span> |{' '}
                        <span className="font-bold">YEN: {sdrBasket.YEN}</span>
                      </p>
                    </div>
                  )}

                  <div>
                    <h3 className="text-lg font-medium text-white mb-4">Select Action</h3>
                    <div className="grid grid-cols-2 gap-4 mb-6">
                      <button
                        onClick={() => handleActionSelection('mint')}
                        className={`w-full py-3 px-4 rounded-lg font-medium transition-all duration-300 ${selectedAction === 'mint'
                            ? 'bg-gradient-to-r from-green-500 to-emerald-600 text-white'
                            : 'bg-gray-800 text-gray-400 hover:bg-gray-700'
                          }`}
                      >
                        Mint Tokens
                      </button>
                      <button
                        onClick={() => handleActionSelection('burn')}
                        className={`w-full py-3 px-4 rounded-lg font-medium transition-all duration-300 ${selectedAction === 'burn'
                            ? 'bg-gradient-to-r from-red-500 to-pink-600 text-white'
                            : 'bg-gray-800 text-gray-400 hover:bg-gray-700'
                          }`}
                      >
                        Burn Tokens
                      </button>
                    </div>

                    <div className="space-y-4">
                      <div className="flex justify-between items-center mb-1">
                        <span className="text-gray-400 text-sm">
                          Balance: {selectedAction === 'mint'
                            ? `${Number(userUSDTBalance).toFixed(2)} USDT`
                            : `${Number(userTokenBalance).toFixed(4)} ${selectedToken.ticker}`}
                        </span>
                        <div className="flex gap-2">
                          <button
                            onClick={handleHalf}
                            className="px-2 py-1 text-xs bg-purple-500/20 text-purple-300 rounded hover:bg-purple-500/30 transition-colors"
                          >
                            HALF
                          </button>
                          <button
                            onClick={handleMax}
                            className="px-2 py-1 text-xs bg-purple-500/20 text-purple-300 rounded hover:bg-purple-500/30 transition-colors"
                          >
                            MAX
                          </button>
                        </div>
                      </div>

                      <input
                        type="number"
                        value={inputValue}
                        onChange={handleInputChange}
                        className="bg-gray-800/50 border border-gray-700 text-white p-3 rounded-lg w-full focus:outline-none focus:border-purple-500"
                        placeholder={selectedAction === 'mint' ? "Enter USDT amount" : `Enter ${selectedToken.ticker} amount`}
                      />

                      <div className="bg-gray-800/50 p-4 rounded-lg">
                        <p className="text-sm text-gray-400">Preview:</p>
                        <p className="text-white font-medium">{getPreviewText()}</p>
                        {selectedAction === 'mint' && (
                          <p className="text-xs mt-2 text-purple-400">
                            {cranniBalance >= 888 * 10 ** 8 ? (
                              "🎉 You qualify for 0% mint fee with CRANNI holdings!"
                            ) : (
                              `💡 Hold 888+ CRANNI for 0% mint fee (Current: ${(cranniBalance / 10 ** 8).toFixed(0)} CRANNI)`
                            )}
                          </p>
                        )}
                      </div>

                      <button
                        className="w-full py-3 px-4 bg-gradient-to-r from-purple-500 to-blue-600 hover:from-purple-600 hover:to-blue-700 text-white rounded-lg font-medium transition-all duration-300"
                        onClick={executeTransaction}
                      >
                        Execute {selectedAction.charAt(0).toUpperCase() + selectedAction.slice(1)}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-span-2 flex items-center justify-center min-h-[400px] rounded-lg bg-gradient-to-br from-gray-900/50 to-gray-800/50 border border-gray-700 md:mt-0 mt-8">
            <div className="text-center text-gray-400">
              <Info className="w-12 h-12 mx-auto mb-4" />
              <p>Select a token to view details and perform actions</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MintBurn;