import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useAccount } from "wagmi";
import { writeContract, readContract } from "@wagmi/core";
import StakingABI from "../abi/staking_abi.json";
import IERC20ABI from "../abi/ERC20_ABI.json";
import {
  stakingContract,
  usdtContractAddress,
  cranniAddress,
  lpToken,
  provider,
  wsprovider,
  bckpProvider,
  CRANNILuckBlocksAddress,
  maticLuckBlocksAddress,
} from "../.config";
import { TrendingUp, Coins, Gift, Droplet } from "lucide-react";
import CRANNILuckBlocks from "../abi/CRANNILuckblocks.json";
import MaticLuckBlocks from "../abi/MATICLuckBlocks.json";
import lpTokenABI from "../abi/LPtoken_abi.json";
import MintBurn from "../components/MintBurn/MintBurn";

const StatCard = ({ title, value, icon: Icon, className = "" }) => (
  <div
    className={`bg-gradient-to-b from-[#010101] to-[#00102a] backdrop-blur-sm border border-purple-500/30 rounded-xl p-6 flex items-center gap-4 hover:bg-purple-900/30 transition-all duration-300 ${className}`}
  >
    <div className="p-3 bg-purple-500/20 rounded-lg border border-purple-500/30">
      {Icon}
    </div>
    <div>
      <p className="text-sm font-medium text-purple-300/80">{title}</p>
      <p className="text-lg font-bold text-white mt-1">{value}</p>
    </div>
  </div>
);

const Token = () => {
  const { address, isConnected } = useAccount();
  const [activeSection, setActiveSection] = useState("tokenInfo");
  const [pendingRewards, setPendingRewards] = useState(0);
  const [inputValue, setInputValue] = useState("0");
  const [amount, setAmount] = useState("0");
  const [totalRewardBalance, setTotalRewardBalance] = useState(0);
  const [pendingRewardTotal, setPendingRewardTotal] = useState(0);
  const [userStakedAmount, setUserStakedAmount] = useState(0);
  const [rewardPerBlock, setRewardPerBlock] = useState(0);
  const [rewardBalance, setRewardBalance] = useState(0);
  const [totalStaked, setTotalStaked] = useState(0);
  const [pendPenalization, setPendPenalization] = useState(0);
  const [lpTokenBalance, setLpTokenBalance] = useState(0);
  const [apr, setApr] = useState(0);
  const [isDepositMode, setIsDepositMode] = useState(true);

  const [cranniPrice, setCranniPrice] = useState(0);
  const [cranniMarketCap, setCranniMarketCap] = useState(0);
  const [cranniTotalSupply, setCranniTotalSupply] = useState(0);
  const [polPrice, setPolPrice] = useState(0);
  const [lpPrice, setLPPrice] = useState(0);

  const crannicontract = new ethers.Contract(
    CRANNILuckBlocksAddress,
    CRANNILuckBlocks.abi,
    provider
  );

  const maticcontract = new ethers.Contract(
    maticLuckBlocksAddress,
    MaticLuckBlocks.abi,
    wsprovider
  );

  const loadPrice = async () => {
    try {
      let cranniCoinPrice = (await crannicontract.getLatestPrice()) / 1000000;
      let mkcap = (cranniCoinPrice * 8888888).toFixed(2);

      let maticCoinPrice =
        (await maticcontract.getLatestPrice()) / 1000000000000000000;

      setPolPrice(parseFloat(maticCoinPrice).toFixed(2));
      setCranniPrice(cranniCoinPrice.toFixed(3).toString());
      setCranniMarketCap(Number(mkcap));
      setCranniTotalSupply(8888888);
    } catch (error) {
      console.error("Error fetching Prices:", error);
    }
  };

  const fetchCranniTokenInfo = async () => {
    try {
      await loadPrice();
    } catch (err) {
      console.error("Error fetching CRANNI token info:", err);
    }
  };
  const fetchUserStakedAmount = async () => {
    try {
      const userInfo = await readContract({
        address: stakingContract,
        abi: StakingABI.abi,
        functionName: "userInfo",
        args: [0, address],
      });

      const formattedAmount = ethers.utils.formatUnits(userInfo[1], 18);

      setUserStakedAmount(formattedAmount);
    } catch (err) {
      console.error("Error fetching user staked amount:", err);
    }
  };

  const fetchContractData = async () => {
    try {
      if (!stakingContract) {
        throw new Error("Staking contract address is not defined");
      }
      if (!address) {
        console.warn(
          "User address is not provided; skipping penalization fetch"
        );
      }

      const calls = [
        readContract({
          address: stakingContract,
          abi: StakingABI.abi,
          functionName: "totalRewardBalance",
        }),
        readContract({
          address: stakingContract,
          abi: StakingABI.abi,
          functionName: "rewardPerBlock",
        }),
        readContract({
          address: stakingContract,
          abi: StakingABI.abi,
          functionName: "rewardBalance",
        }).catch((err) => {
          console.warn("rewardBalance failed:", err.message);
          return 0; // Fallback value
        }),
        readContract({
          address: stakingContract,
          abi: StakingABI.abi,
          functionName: "pendingRewardTotal",
        }).catch((err) => {
          console.warn("pendingRewardTotal failed:", err.message);
          return 0; // Fallback value to avoid UI breakage
        }),
        readContract({
          address: stakingContract,
          abi: StakingABI.abi,
          functionName: "totalStaked",
        }),
      ];

      if (address) {
        calls.push(
          readContract({
            address: stakingContract,
            abi: StakingABI.abi,
            functionName: "pendingPenalization",
            args: [address],
          })
        );
      }

      const [
        totalReward,
        rewardBlock,
        rewardBal,
        pendingRewardTotal,
        totalStakedAmt,
        penalizationTime,
      ] = await Promise.all(calls);

      setTotalRewardBalance(ethers.utils.formatUnits(totalReward, 6));
      setRewardPerBlock(ethers.utils.formatUnits(rewardBlock, 6));
      setRewardBalance(ethers.utils.formatUnits(rewardBal, 6));
      setTotalStaked(ethers.utils.formatUnits(totalStakedAmt, 18));
      setPendingRewardTotal(ethers.utils.formatUnits(pendingRewardTotal, 6));

      if (penalizationTime) {
        const currentTimestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
        const penaltyEndTimestamp = parseInt(penalizationTime.toString(), 10); // Convert BigNumber to a regular number
        const timeDifferenceInSeconds = penaltyEndTimestamp - currentTimestamp;

        if (timeDifferenceInSeconds > 0) {
          if (timeDifferenceInSeconds >= 24 * 60 * 60) {
            // Difference in days
            const timeDifferenceInDays = Math.ceil(
              timeDifferenceInSeconds / (24 * 60 * 60)
            ); // Convert seconds to days
            timeDifferenceInDays > 1
              ? setPendPenalization(`${timeDifferenceInDays} days`)
              : setPendPenalization(`${timeDifferenceInDays} day`);
          } else {
            // Difference in hours, minutes, and seconds
            const hours = Math.floor(timeDifferenceInSeconds / (60 * 60));
            const minutes = Math.floor(
              (timeDifferenceInSeconds % (60 * 60)) / 60
            );
            const seconds = timeDifferenceInSeconds % 60;

            const formattedTime = `${
              hours > 0 ? `${hours}h ` : ""
            }${minutes}m ${seconds}s`;
            setPendPenalization(`${formattedTime}`);
          }
        } else {
          setPendPenalization("0"); // No penalty remaining
        }
      }
    } catch (err) {
      console.error("Error fetching contract data:", err.message, err.stack);
    }
  };

  const setMaxAmount = () => {
    if (isDepositMode) {
      setAmount(lpTokenBalance);
    } else {
      setAmount(userStakedAmount);
    }
  };

  const fetchPendingRewards = async () => {
    try {
      const rewards = await readContract({
        address: stakingContract,
        abi: StakingABI.abi,
        functionName: "pendingReward",
        args: [address],
      });
      const formattedRewards = ethers.utils.formatUnits(rewards, 6);
      setPendingRewards(formattedRewards);
    } catch (err) {
      console.error("Error fetching rewards:", err);
    }
  };

  const calculateAPR = async () => {
    try {
      // Create LP Token Contract instance
      const lpTokenContract = new ethers.Contract(
        lpToken,
        lpTokenABI.abi,
        bckpProvider
      );

      // Get token addresses and reserves
      const token0 = await lpTokenContract.token0();
      const token1 = await lpTokenContract.token1();
      const reserves = await lpTokenContract.getReserves();

      // Get the token contracts for token0 and token1
      const token0Contract = new ethers.Contract(
        token0,
        IERC20ABI.abi,
        bckpProvider
      );
      const token1Contract = new ethers.Contract(
        token1,
        IERC20ABI.abi,
        bckpProvider
      );

      // Get token decimals
      const token0Decimals = await token0Contract.decimals();
      const token1Decimals = await token1Contract.decimals();

      // Calculate token amounts in the pool
      const reserve0 = ethers.utils.formatUnits(reserves[0], token0Decimals);
      const reserve1 = ethers.utils.formatUnits(reserves[1], token1Decimals);

      const token0Price = parseFloat(polPrice);
      const token1Price = parseFloat(cranniPrice);

      // Calculate total liquidity in USD
      const liquidityValue = reserve0 * token0Price + reserve1 * token1Price;

      // Get total LP token supply
      const totalSupply = await lpTokenContract.totalSupply();
      const formattedTotalSupply = ethers.utils.formatUnits(totalSupply, 18); // Assuming 18 decimals, adjust if different

      // Calculate LP token price
      const lpTokenPrice = liquidityValue / parseFloat(formattedTotalSupply);

      const formmatedPrice = parseFloat(lpTokenPrice).toFixed(2);

      setLPPrice(formmatedPrice);

      // Rest of your APR calculation remains the same
      const BLOCKS_PER_YEAR = (365 * 24 * 60 * 60) / 3;
      const rewardTokenPrice = 1;
      const yearlyRewards =
        parseFloat(rewardPerBlock) * BLOCKS_PER_YEAR * rewardTokenPrice;
      const totalStakedValue = parseFloat(totalStaked) * lpTokenPrice;

      if (totalStakedValue > 0) {
        const calculatedApr = (yearlyRewards / totalStakedValue) * 100;
        setApr(calculatedApr.toFixed(2));
      } else {
        setApr(0);
      }
    } catch (err) {
      console.error("Error calculating APR:", err);
      setApr(0);
    }
  };

  const fetchLpTokenBalance = async () => {
    try {
      const lpTokenContract = new ethers.Contract(
        lpToken,
        IERC20ABI.abi,
        wsprovider
      );
      const balance = await lpTokenContract.balanceOf(address);
      let formattedBalance = ethers.utils.formatUnits(balance, 18);
      formattedBalance = parseFloat(formattedBalance).toFixed(18);
      setLpTokenBalance(formattedBalance);
    } catch (err) {
      console.error("Error fetching LP token balance:", err);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    setInputValue(value);

    // Debounce updating the state
    clearTimeout(window.amountTimeout);
    window.amountTimeout = setTimeout(() => {
      setAmount(value);
    }, 300);
  };

  const approveForAutoBuy = async () => {
    //  const cranniToken = new ethers.Contract(cranniAddress, IERC20ABI.abi, provider);

    if (!address) {
      Swal.fire({
        icon: "warning",
        title: "Not Connected",
        text: "Connect your Wallet to approve for Direct Automated Buy/Sell",
        timer: 2000,
        timerProgressBar: true,
      });
      return;
    }

    // Check current allowance
    const allowance = await readContract({
      address: cranniAddress,
      abi: IERC20ABI.abi,
      functionName: "allowance",
      args: [address, cranniAddress],
    });

    if (allowance > 0) {
      Swal.fire({
        icon: "success",
        title: "Already Approved",
        text: "already approved contract token for Direct Automated Buy/Sell",
        timer: 2000,
        timerProgressBar: true,
      });
      return;
    }

    // Approve with MAX_UINT256
    const approveHash = await writeContract({
      address: cranniAddress,
      abi: IERC20ABI.abi,
      functionName: "approve",
      args: [cranniAddress, ethers.constants.MaxUint256],
    });

    // Wait for approval transaction to be mined
    const approveReceipt = await provider.waitForTransaction(approveHash.hash);
    if (!approveReceipt.status) throw new Error("Approval transaction failed.");

    // Show Swal notification for successful approval
    Swal.fire({
      icon: "success",
      title: "Approval Successful",
      text: "approved contract token for Direct Automated Buy/Sell",
      timer: 2000,
      timerProgressBar: true,
    });
  };

  const handleDeposit = async () => {
    if (!amount) return Swal.fire("Error", "Please enter an amount", "error");
    try {
      const lpTokenContract = new ethers.Contract(
        lpToken,
        IERC20ABI.abi,
        provider
      );
      const parsedAmount = ethers.utils.parseUnits(amount, 18);

      // Get lower amount (minimum stake) from contract
      const lowerAmount = await readContract({
        address: stakingContract,
        abi: StakingABI.abi,
        functionName: "lowerAmount",
      });

      // Check if deposit meets minimum requirements
      if (parsedAmount.lt(lowerAmount)) {
        return Swal.fire(
          "Error",
          `Minimum deposit amount is ${ethers.utils.formatUnits(
            lowerAmount,
            18
          )}`,
          "error"
        );
      }

      // Check current allowance
      const allowance = await readContract({
        address: lpToken,
        abi: IERC20ABI.abi,
        functionName: "allowance",
        args: [address, stakingContract],
      });

      console.log(allowance);

      // Only approve if allowance is 0
      if (allowance === 0n) {
        // Approve with MAX_UINT256
        const approveHash = await writeContract({
          address: lpToken,
          abi: IERC20ABI.abi,
          functionName: "approve",
          args: [stakingContract, ethers.constants.MaxUint256],
        });

        // Wait for approval transaction to be mined
        const approveReceipt = await provider.waitForTransaction(
          approveHash.hash
        );
        if (!approveReceipt.status)
          throw new Error("Approval transaction failed.");

        // Show Swal notification for successful approval
        Swal.fire({
          icon: "success",
          title: "Approval Successful",
          text: "LP Token has been approved for staking",
          timer: 2000,
          timerProgressBar: true,
        });
      }

      // Deposit transaction
      const depositHash = await writeContract({
        address: stakingContract,
        abi: StakingABI.abi,
        functionName: "deposit",
        args: [parsedAmount],
      });

      // Wait for deposit transaction to be mined
      const depositReceipt = await provider.waitForTransaction(
        depositHash.hash
      );
      if (!depositReceipt.status)
        throw new Error("Deposit transaction failed.");

      // Reset amount and notify success
      setAmount("0");
      Swal.fire("Success", "Deposit successful", "success");

      // Refresh data
      fetchContractData();
      fetchPendingRewards();
      fetchLpTokenBalance();
      fetchUserStakedAmount();
    } catch (err) {
      console.error("Error during deposit:", err);
      Swal.fire("Error", "Transaction failed. Try again later.", "error");
    }
  };

  const claimRewards = async () => {
    try {
      // Call withdraw function
      const withdrawHash = await writeContract({
        address: stakingContract,
        abi: StakingABI.abi,
        functionName: "withdraw",
        args: [0],
      });

      // Wait for the transaction to be mined
      const withdrawReceipt = await provider.waitForTransaction(
        withdrawHash.hash
      );
      if (!withdrawReceipt.status)
        throw new Error("Withdrawal transaction failed.");

      // Reset input field and show success message
      setAmount("0");
      Swal.fire("Success", "Rewards Claimed successful", "success");

      // Refresh data
      fetchContractData();
      fetchPendingRewards();
      fetchLpTokenBalance();
      fetchUserStakedAmount();
    } catch (err) {
      console.error("Error during rewards withdrawal:", err);
      Swal.fire("Error", "Transaction failed. Try again later.", "error");
    }
  };

  const handleWithdraw = async () => {
    if (!amount) amount = 0;

    try {
      const parsedAmount = ethers.utils.parseUnits(amount, 18);

      // Check if there's a penalty
      if (pendPenalization && pendPenalization !== "0" && amount > 0) {
        const result = await Swal.fire({
          title: "Withdrawal Penalty Warning",
          html: `You currently have a withdrawal penalty period of ${pendPenalization}.<br>
                 Proceeding with withdrawal now will result in a 10% penalty fee.<br><br>
                 Do you want to continue?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, withdraw anyway",
          cancelButtonText: "No, cancel",
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
        });

        if (!result.isConfirmed) {
          return;
        }
      }

      // Call withdraw function
      const withdrawHash = await writeContract({
        address: stakingContract,
        abi: StakingABI.abi,
        functionName: "withdraw",
        args: [parsedAmount],
      });

      // Wait for the transaction to be mined
      const withdrawReceipt = await provider.waitForTransaction(
        withdrawHash.hash
      );
      if (!withdrawReceipt.status)
        throw new Error("Withdrawal transaction failed.");

      // Reset input field and show success message
      setAmount("0");
      Swal.fire("Success", "Withdrawal successful", "success");

      // Refresh data
      fetchContractData();
      fetchPendingRewards();
      fetchLpTokenBalance();
      fetchUserStakedAmount();
    } catch (err) {
      console.error("Error during withdrawal:", err);
      Swal.fire("Error", "Transaction failed. Try again later.", "error");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchCranniTokenInfo();
      await fetchContractData();
      await calculateAPR();
      if (isConnected) {
        await fetchPendingRewards();
        await fetchLpTokenBalance();
        await fetchUserStakedAmount();
      }
    };

    // Call the async function
    fetchData();

    // Set up interval to refresh pending rewards every minute
    const rewardsInterval = setInterval(async () => {
      if (isConnected && address) {
        await fetchPendingRewards();
      }
    }, 60000);

    // Clean up interval on component unmount
    return () => clearInterval(rewardsInterval);
  }, [isConnected, address, activeSection]);

  const SubMenu = () => (
    <div className="flex justify-center mb-8">
      <div className=" backdrop-blur-sm border border-purple-500/30 rounded-xl inline-flex">
        <button
          onClick={() => setActiveSection("tokenInfo")}
          className={`px-6 py-3 rounded-xl transition-all duration-300 font-bold text-center items-center justify-center rounded-[10px] text-md font-tcbregular italic ${
            activeSection === "tokenInfo"
              ? "bg-gradient-to-r from-purple-500 to-pink-500 text-white"
              : "text-purple-300/60 hover:text-white"
          }`}
        >
          Token Info
        </button>
        <button
          onClick={() => setActiveSection("stakingDashboard")}
          className={`px-6 py-3 rounded-xl transition-all duration-300 font-bold text-center items-center justify-center rounded-[10px] text-md font-tcbregular italic ${
            activeSection === "stakingDashboard"
              ? "bg-gradient-to-r from-purple-500 to-pink-500 text-white"
              : "text-purple-300/60 hover:text-white"
          }`}
        >
          Staking Dashboard
        </button>
        <button
          onClick={() => setActiveSection("Cynths")}
          className={`px-6 py-3 rounded-xl transition-all duration-300 font-bold text-center items-center justify-center rounded-[10px] text-md font-tcbregular italic ${
            activeSection === "Cynths"
              ? "bg-gradient-to-r from-purple-500 to-pink-500 text-white"
              : "text-purple-300/60 hover:text-white"
          }`}
        >
          Cynths
        </button>
      </div>
    </div>
  );

  const CranniInfoSection = () => (
    <div className="bg-gradient-to-b from-[#010101] to-[#00102a] backdrop-blur-sm border border-purple-500/30 rounded-xl p-6 mt-6">
      <h3 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400 mb-4">
        CRANNI Token
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="bg-gradient-to-b from-[#010101] to-[#00102a] p-4 rounded-lg border border-purple-500/30">
          <p className="text-purple-300/60 text-sm">Current Price</p>
          <p className="text-white font-bold text-lg">${cranniPrice}</p>
        </div>
        <div className="bg-gradient-to-b from-[#010101] to-[#00102a] p-4 rounded-lg border border-purple-500/30">
          <p className="text-purple-300/60 text-sm">Market Cap</p>
          <p className="text-white font-bold text-lg">
            ${cranniMarketCap.toLocaleString()}
          </p>
        </div>
        <div className="bg-gradient-to-b from-[#010101] to-[#00102a] p-4 rounded-lg border border-purple-500/30">
          <p className="text-purple-300/60 text-sm">Total Supply</p>
          <p className="text-white font-bold text-lg">
            {cranniTotalSupply.toLocaleString()}
          </p>
        </div>
      </div>
      <div className="mt-4 text-center">
        <p className="text-purple-300/80 mb-4">
          CRANNI: Crypto Revenue and Node Network Income Token
        </p>
        <div className="flex justify-center gap-4">
          {/* <a
            href="https://app.uniswap.org/#/add/v2/CRANNI/USDT"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-green-500 to-emerald-500 hover:from-green-600 hover:to-emerald-600 transition-all duration-300 rounded-xl text-white font-bold"
          >
            Add Liquidity on Uniswap
          </a> */}
          <a
            href="https://pancakeswap.finance/v2/add/tBNB/0x8C426Bc4506b4f7BfB21cF67A342e0E7EBe0980f"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 transition-all duration-300 rounded-xl text-white font-bold"
          >
            Add Liquidity on SWAP DEX
          </a>
        </div>
      </div>
    </div>
  );

  const TokenInfoContent = () => (
    <div className="relative w-full mt-5 text-center">
      <div className="text-white">
        <div className="p-1 border-rounded text-lg leading-relaxed space-y-4">
          <p>
            Beyond traditional cryptocurrency utility, CRANNI serves as a
            versatile asset with multiple critical functions:
          </p>
          <ul className="list-disc list-inside text-left space-y-2">
            <li>
              <strong>Synthetic Asset Collateralization:</strong> Use CRANNI as
              premium collateral for creating and trading synthetic assets,
              enabling advanced financial derivatives
            </li>
            <li>
              <strong>Liquidity Pool Staking:</strong> Earn substantial revenue
              by providing liquidity and staking CRANNI-paired tokens in our
              advanced staking protocols
            </li>
            <li>
              <strong>Smart Contract Utility:</strong> Unlock automated
              functions and advanced features within our ecosystem's smart
              contract framework
            </li>
          </ul>

          <div className="playcard border border-purple-500/30 rounded-xl p-4 mt-6">
            <h3 className="text-2xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
              Revolutionary Buy/Sell Mechanism
            </h3>
            <p className="mb-4">
              CRANNI introduces a groundbreaking, wallet-native buying and
              selling mechanism directly through the smart contract:
            </p>
            <div className="bg-purple-900/50 p-4 rounded-lg">
              <p className="mb-2">
                <strong>Buying CRANNI:</strong>
                <span>
                  {" "}
                  <br></br> Simply send POL directly to the CRANNI smart
                  contract address, and tokens will be automatically purchased
                  from the decentralized liquidity pool.{" "}
                </span>
              </p>
              <p className="mb-2">
                <strong>Selling CRANNI:</strong>

                <span>
                  {" "}
                  <br></br> - Send 0.01 POL to instantly sell your entire CRANNI
                  balance{" "}
                </span>
                <span>
                  {" "}
                  <br></br> - Send 0.005 POL to sell exactly half of your CRANNI
                  tokens{" "}
                </span>
                <span>
                  {" "}
                  <br></br> - Send 0.0025 POL to sell exactly 1/4 of your CRANNI
                  tokens{" "}
                </span>
              </p>
              <p className="italic text-purple-300">
                No additional steps, exchanges, or complex interfaces required –
                trade directly from your wallet!
              </p>
            </div>

            <button
              onClick={() => approveForAutoBuy()}
              className="justify-content-center items-center mt-2 gap-2 px-6 py-3 bg-gradient-to-r from-green-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 transition-all duration-300 rounded-xl text-white font-bold"
            >
              Approve for Auto Buy/Sell
            </button>
          </div>

          <p className="mt-4">
            Designed with a dynamic scarcity mechanism and strategic utility,
            CRANNI represents the next evolution of blockchain-powered financial
            instruments.
          </p>
        </div>
      </div>
    </div>
  );

  const inputRef = React.useRef();

  const StakingDashboardContent = () => (
    <div className="flex flex-col gap-8">
      {/* Header */}
      <div className="text-center">
        <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400 mb-2 mt-5">
          Staking Dashboard
        </h1>
        <p className="text-purple-300/60">
          Manage your Liquidity Tokens and receive ecosystem revenue rewards.
        </p>
      </div>

      {/* Main Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard
          title="APR"
          value={`${apr}%`}
          icon={<TrendingUp color="#10b981" size={24} />}
          className="bg-green-900/20 border-green-500/30"
        />
        <StatCard
          title="Your Staked Amount"
          value={`${parseFloat(userStakedAmount).toFixed(
            8
          )} CRANNI-POL ($${parseFloat(userStakedAmount * lpPrice).toFixed(
            2
          )})`}
          icon={<Coins color="#3b82f6" size={24} />}
        />
        <StatCard
          title="Pending Rewards"
          value={`${pendingRewards} USDT`}
          icon={<Gift color="#8b5cf6" size={24} />}
        />
        <StatCard
          title="LP Token Balance"
          value={`${parseFloat(lpTokenBalance).toFixed(
            8
          )} CRANNI-POL ($${parseFloat(lpTokenBalance * lpPrice).toFixed(2)})`}
          icon={<Droplet color="#ec4899" size={24} />}
        />
      </div>

      {/* Action Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Deposit/Withdraw Card */}
        <div className="bg-gradient-to-b from-[#010101] to-[#00102a] backdrop-blur-sm border border-purple-500/30 rounded-xl p-6">
          <div className="flex gap-4 mb-6">
            <button
              onClick={() => setIsDepositMode(true)}
              className={`flex-1 py-2 px-4 rounded-lg font-bold transition-all duration-300 ${
                isDepositMode
                  ? "bg-gradient-to-r from-purple-500 to-pink-500 text-white"
                  : "text-purple-300/60 hover:text-white"
              }`}
            >
              Deposit
            </button>
            <button
              onClick={() => setIsDepositMode(false)}
              className={`flex-1 py-2 px-4 rounded-lg font-bold transition-all duration-300 ${
                !isDepositMode
                  ? "bg-gradient-to-r from-purple-500 to-pink-500 text-white"
                  : "text-purple-300/60 hover:text-white"
              }`}
            >
              Withdraw
            </button>
          </div>
          <div className="space-y-4">
            <div className="relative">
              <input
                ref={inputRef}
                type="text"
                inputMode="decimal"
                placeholder={`Amount to ${
                  isDepositMode ? "Deposit" : "Withdraw"
                }`}
                value={amount}
                autoFocus={inputRef.current === document.activeElement}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, "");
                  if (value !== amount) {
                    setAmount(value);
                    inputRef.current?.focus();
                  }
                }}
                className="w-full bg-purple-900/30 border border-purple-500/30 rounded-xl p-4 text-white placeholder:text-purple-300/40 focus:outline-none focus:ring-2 focus:ring-purple-500/40 pr-20"
              />
              <button
                onClick={setMaxAmount}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 px-3 py-1 bg-purple-500/30 hover:bg-purple-500/50 rounded-lg text-sm font-bold text-purple-300 transition-all duration-300"
              >
                MAX
              </button>
            </div>
            <button
              onClick={isDepositMode ? handleDeposit : handleWithdraw}
              className={`w-full transition-all duration-300 rounded-xl py-4 text-white font-bold shadow-lg ${
                isDepositMode
                  ? "bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 shadow-purple-500/20"
                  : "bg-gradient-to-r from-red-500 to-pink-500 hover:from-red-600 hover:to-pink-600 shadow-red-500/20"
              }`}
            >
              {isDepositMode ? "Deposit" : "Withdraw"}
            </button>
          </div>
        </div>

        {/* Stats Card */}
        <div className="bg-gradient-to-b from-[#010101] to-[#00102a] backdrop-blur-sm border border-purple-500/30 rounded-xl p-6">
          <h3 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400 mb-4">
            Statistics
          </h3>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <span className="text-purple-300/60">
                Total Staked CRANNI-POL LP (TVL):
              </span>
              <span className="text-white font-bold">
                {parseFloat(totalStaked).toFixed(3)} LP (${" "}
                {parseFloat(totalStaked * lpPrice).toFixed(2)})
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-purple-300/60">
                Contract Total Reward Balance:
              </span>
              <span className="text-white font-bold">
                {totalRewardBalance} USDT
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-purple-300/60">
                Month Pending Reward Balance:
              </span>
              <span className="text-white font-bold">{rewardBalance} USDT</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-purple-300/60">Reward per Block:</span>
              <span className="text-white font-bold">
                {rewardPerBlock} USDT
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-purple-300/60">
                Your Withdraw Penalty Time (10% fee):
              </span>
              <span className="text-white font-bold">{pendPenalization}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex gap-4 justify-center">
        <button
          onClick={() => claimRewards()}
          className="px-8 py-4 bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 transition-all duration-300 rounded-xl text-white font-bold shadow-lg shadow-purple-500/20"
        >
          Claim Rewards
        </button>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b via-[#202e44] from-black to-[#596b87] p-8">
      <div className="max-w-7xl mx-auto">
        <SubMenu />
        {activeSection === "tokenInfo" && <CranniInfoSection />}
        {activeSection === "tokenInfo" && <TokenInfoContent />}
        {activeSection === "stakingDashboard" && <StakingDashboardContent />}
        {activeSection === "Cynths" && <MintBurn />}
      </div>
    </div>
  );
};
export default Token;
