// hooks/useCynths.js
import { useWalletClient } from 'wagmi';
import { writeContract, readContract } from '@wagmi/core';
import { ethers } from 'ethers';
import {
  cynthsAddress,
  erc20Address,
  wsprovider,
  provider,
  bckpProvider,
  cranniAddress,
} from '../.config';
import cynthAbi from '../abi/Cynths.json';
import erc20abi from '../abi/ERC20_ABI.json';
import Swal from 'sweetalert2';

export const useCynths = () => {

  const { data: walletClient } = useWalletClient();

  const signerOrProvider = walletClient ? walletClient : provider;


  const handleTransaction = async (txFunc) => {
    try {
      const tx = await txFunc();

      if (tx.wait) await tx.wait();
      return tx;
    } catch (error) {
      console.error('Transaction error:', error);
      throw error;
    }
  };

  const checkAllowance = async (tokenAddress, owner, spender, requiredAmount) => {
    const currentAllowance = await readContract({
      address: tokenAddress,
      abi: erc20abi.abi,
      functionName: 'allowance',
      args: [owner, spender],
    });
    return ethers.BigNumber.from(currentAllowance).gte(requiredAmount);
  };

    const getCurrentFee = async () => {
      const fee = await readContract({
        address: cynthsAddress,
        abi: cynthAbi,
        functionName: 'currentFee',
      });
      return fee; // Fee in unit, e.g., 100 = 1%
    };

    const getCranniBalance = async (account) => {
      const balance = await readContract({
        address: cranniAddress,
        abi: erc20abi.abi,
        functionName: 'balanceOf',
        args: [account],
      });
      return balance;
    };

  // === WRITE ===
  const mint = async (ticker, amountUSD) => {

    const weiAmount = ethers.utils.parseUnits(amountUSD.toString(), 6);
    return handleTransaction(async () => {
 
      Swal.fire({
        title: 'Approval Required',
        text: 'Approving USDT for minting...',
        icon: 'info',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  

      const approveTx = await writeContract({
        address: erc20Address,
        abi: erc20abi.abi,
        functionName: 'approve',
        args: [cynthsAddress, weiAmount],
        account: walletClient?.accountAddress,
      });

      // Wait for approval transaction to be mined
      const approveReceipt = await provider.waitForTransaction(
        approveTx.hash
      );

      if (!approveReceipt.status)
        throw new Error("Approval transaction failed.");

      // Show Swal notification for successful approval
      Swal.close();
      Swal.fire({
        title: 'Approved',
        text: 'USDT approved for minting.',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false,
      });

      return await writeContract({
        address: cynthsAddress,
        abi: cynthAbi,
        functionName: 'mintSynth',
        args: [ticker, weiAmount],
        account: walletClient?.accountAddress,
      });
    });
  };
  

const burn = async (ticker, amountSynth,walletClient) => {

  const weiAmount = ethers.utils.parseUnits(amountSynth.toString(), 18);


  const tokens = await getTokenList();
  const tokenData = tokens.find((token) => token.ticker === ticker);

  if (!tokenData) {
    throw new Error(`Token with ticker ${ticker} not found`);
  }

  return handleTransaction(async () => {
        
    const owner = walletClient?.account?.address;
    const spender = cynthsAddress;


    const allowed = await checkAllowance(tokenData.tokenAddress, owner, spender, weiAmount);

    if (!allowed) {
  
      Swal.fire({
        title: 'Approval Required',
        text: `Approving ${ticker} for burning...`,
        icon: 'info',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const approveTx = await writeContract({
        address: tokenData.tokenAddress,
        abi: erc20abi.abi,
        functionName: 'approve',
        args: [spender, weiAmount],
        account: owner,
      });
       // Wait for approval transaction to be mined
       const approveReceipt = await provider.waitForTransaction(
        approveTx.hash
      );

      if (!approveReceipt.status)
        throw new Error("Approval transaction failed.");

      // Show Swal notification for successful approval
      Swal.close(); 
      Swal.fire({
        title: 'Approved',
        text: `${ticker} approved for burning.`,
        icon: 'success',
        timer: 1500,
        showConfirmButton: false,
      });

    }

    const burnTx = await writeContract({
      address: cynthsAddress,
      abi: cynthAbi,
      functionName: 'burnSynth',
      args: [ticker, weiAmount],
      account: owner,
    });
    return burnTx;
  });
};

  // === READ ===
  const getTokenPrice = async (ticker) => {
    const price = await readContract({
      address: cynthsAddress,
      abi: cynthAbi,
      functionName: 'getLatestPrice',
      args: [ticker],
      chainId: provider?.network?.chainId,
    });
    return parseFloat(ethers.utils.formatUnits(price, 6)).toFixed(2);
  };

  const getTotalMinted = async () => {
    const total = await readContract({
      address: cynthsAddress,
      abi: cynthAbi,
      functionName: 'getTotalMinted',
    });
    return parseFloat(ethers.utils.formatUnits(total, 6)).toFixed(2);
  };

  const getTotalSupply = async () => {
    const total = await readContract({
      address: cynthsAddress,
      abi: cynthAbi,
      functionName: 'getTotalSupply',
    });
    return parseFloat(ethers.utils.formatUnits(total, 6)).toFixed(2);
  };


  const getTokenList = async () => {
    const tokens = await readContract({
      address: cynthsAddress,
      abi: cynthAbi,
      functionName: 'getTokens',
    });
    return tokens.map((token) => ({
      ticker: ethers.utils.parseBytes32String(token.ticker),
      tokenAddress: token.tokenAddress,
      category: token.category,
      description: token.description,
    }));
  };

  const previewMint = async (ticker, amountUSD) => {
    const weiAmount = ethers.utils.parseUnits(amountUSD.toString(), 6);
    const result = await readContract({
      address: cynthsAddress,
      abi: cynthAbi,
      functionName: 'previewMintAmount',
      args: [ticker, weiAmount],
    });
    return ethers.utils.formatUnits(result, 18);
  };

  const previewBurn = async (ticker, amountSynth) => {
    const weiAmount = ethers.utils.parseUnits(amountSynth.toString(), 18);
    const result = await readContract({
      address: cynthsAddress,
      abi: cynthAbi,
      functionName: 'previewReturnOnBurn',
      args: [ticker, weiAmount],
    });
    return ethers.utils.formatUnits(result, 6);
  };

  const getBoldPrice = async () => {
    const price = await readContract({
      address: cynthsAddress,
      abi: cynthAbi,
      functionName: 'getBoldPrice',
    });
    return parseFloat(parseFloat(ethers.utils.formatUnits(price, 6)).toFixed(2)).toFixed(2);
  };

  const getSdrPrice = async () => {
    const price = await readContract({
      address: cynthsAddress,
      abi: cynthAbi,
      functionName: 'getSdrPrice',
    });
    return parseFloat(ethers.utils.formatUnits(price, 6)).toFixed(2);
  };

  const getMuskPrice = async () => {
    const price = await readContract({
      address: cynthsAddress,
      abi: cynthAbi,
      functionName: 'getMuskPrice',
    });
    return parseFloat(ethers.utils.formatUnits(price, 6)).toFixed(2);
  };

  const getPrivPrice = async () => {
    const price = await readContract({
      address: cynthsAddress,
      abi: cynthAbi,
      functionName: 'getPrivPrice',
    });
    return parseFloat(ethers.utils.formatUnits(price, 6)).toFixed(2);
  };

  const getBtcWeight = async () => {
    const btcWeight = await readContract({
      address: cynthsAddress,
      abi: cynthAbi,
      functionName: 'btcWeight',
    });
    return btcWeight + '%';
  };

  const getGoldWeight = async () => {
    const goldWeight = await readContract({
      address: cynthsAddress,
      abi: cynthAbi,
      functionName: 'goldWeight',
    });
    return goldWeight + '%';
  };

  const getSdrBasket = async () => {
    try {
      const [sdrUSD, sdrCNY, sdrYEN, sdrGBP, sdrEUR] = await Promise.all([
        readContract({
          address: cynthsAddress,
          abi: cynthAbi,
          functionName: 'sdrUSD',
        }),
        readContract({
          address: cynthsAddress,
          abi: cynthAbi,
          functionName: 'sdrCNY',
        }),
        readContract({
          address: cynthsAddress,
          abi: cynthAbi,
          functionName: 'sdrYEN',
        }),
        readContract({
          address: cynthsAddress,
          abi: cynthAbi,
          functionName: 'sdrGBP',
        }),
        readContract({
          address: cynthsAddress,
          abi: cynthAbi,
          functionName: 'sdrEUR',
        }),
      ]);

      const formatSdrValue = (value) => {
        const bigNumberValue = ethers.BigNumber.from(value);
        return bigNumberValue.div(ethers.BigNumber.from(10).pow(13)).toNumber() / 100000;
      };

      return {
        USD: formatSdrValue(sdrUSD), // 57813 * 10**13 = 0.57813
        CNY: formatSdrValue(sdrCNY), // 109930 * 10**13 = 1.09930
        YEN: formatSdrValue(sdrYEN), // 1345200 * 10**13 = 13.4520
        GBP: formatSdrValue(sdrGBP), // 8087 * 10**13 = 0.08087
        EUR: formatSdrValue(sdrEUR), // 37379 * 10**13 = 0.37379
      };
    } catch (error) {
      console.error('Error fetching SDR basket:', error);
      return null;
    }
  };

  const formatCurrency = (value, currency) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 5,
    }).format(value);
  };

  return {
    mint,
    burn,
    getTokenPrice,
    getTotalMinted,
    getTotalSupply,
    getTokenList,
    previewMint,
    previewBurn,
    getBoldPrice,
    getSdrPrice,
    getMuskPrice,
    getPrivPrice,
    getBtcWeight,
    getGoldWeight,
    getSdrBasket,
    formatCurrency,
    getCurrentFee,
    getCranniBalance
  };
};
